import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function BenefitFeaturesImageWKB({ heading, subheading }) {
  return (
    <div className="relative bg-gray-50 pt-16 overflow-hidden sm:pt-24 lg:pt-8">
      <div className="mx-auto max-w-md px-4 sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div className="text-center">
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {heading}
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">{subheading}</p>
        </div>
        <dl className="mt-4 mb-24 md:grid md:grid-cols-3 md:gap-x-10 md:gap-y-10">
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-30">
            <div className="flex mb-3 font-extrabold text-3xl items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
              1
            </div>
            <h2 className="font-extrabold mb-2 text-gray-900">Selecteer het type project</h2>
            <p className="text-gray-500">
              Bij het aanmaken van een project kun je het bijpassende template aanvinken wat voor
              jouw project van toepassing is.
            </p>
            <StaticImage src="../../img/wkb1.png" alt="wkb1" />
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-30">
            <div className="flex mb-3 font-extrabold text-3xl items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
              2
            </div>
            <h2 className="font-extrabold mb-2 text-gray-900">Beantwoord de vragen</h2>
            <p className="text-gray-500">
              We hebben per type project vragenlijsten ontwikkeld die er voor zorgen dat je niets
              over het hoofd ziet.{' '}
            </p>
            <StaticImage src="../../img/wkb2.png" alt="wkb2" />
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-30">
            <div className="flex mb-3 font-extrabold text-3xl items-center justify-center h-12 w-12 rounded-md bg-yellow-400 text-white">
              3
            </div>
            <h2 className="font-extrabold mb-2 text-gray-900">Vink je To-Do’s af</h2>
            <p className="text-gray-500">
              Op basis van de vragen die je hebt beantwoord maken we To-Do lijst aan van de punten
              waar het project aan moet voldoen.
            </p>
            <StaticImage src="../../img/wkb3.png" alt="wkb3" />
          </div>
        </dl>
      </div>
    </div>
  );
}
