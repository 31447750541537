import React from 'react';
import { LightningBoltIcon, ChatAltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/outline';
import Layout from '../components/layout';
import BenefitHeroWKB from '../components/WKB/BenefitHeroWKB';
import BenefitFeaturesWkb from '../components/WKB/BenefitFeaturesWkb';
import BenefitFeaturesImageWKB from '../components/WKB/BenefitFeaturesImageWKB';
import BenefitCtaWKB from '../components/WKB/BenefitCtaWKB';
import { SEO } from '../components/SEO/SEO';

const FeaturesList = [
  {
    name: 'Geldt de wet voor mij?',
    description:
      'Veel aannemers die we spreken geven aan niet goed te weten wat de Wet voor hen in de praktijk betekent. Onterecht wordt vaak gedacht dat de wet voor hun type bedrijf niet van toepassing is. Dat is niet correct. Ieder bedrijf krijgt er mee te maken, of je nou dakkappelen plaatst, keukens installeert of volledige woningen renoveert. We vinden het daarom belangrijk jou te helpen de wet in de praktijk goed toe te passen.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Consumentendossier',
    description:
      'Het consumentendossier heeft als doel de opdrachtgever ‘volledig inzicht’ te geven in de nakoming van de gemaakte afspraken. In de praktijk betekent dit dat er een dossier moet worden gemaakt waarin alle relevante documenten zoals tekeningen, garanties en certificaten zijn opgenomen, aangevuld met informatie over de uitgevoerde werkzaamheden. Zo krijgt de opdrachtgever inzicht in de nakoming van de overeenkomst.',
    icon: ChatAltIcon,
  },
  {
    name: 'Omgekeerde bewijslast',
    description:
      'Met ingang van de Wet zal de bewijslast worden omgedraaid en wordt dit de verantwoordelijkheid van de aannemer. Bij gebreken na oplevering ben je hier dus verantwoordelijk voor. Wanneer deze gebreken niet door jou zijn veroorzaakt, moet je dit kunnen aantonen om hier niet voor aansprakelijk te worden gehouden. In de praktijk betekent dit dat je jouw uitgevoerde werk goed moet documenteren en vastleggen.',
    icon: ScaleIcon,
  },
  {
    name: 'Kwaliteitsborger',
    description:
      'Wanneer je werkt aan vergunningsplichtige werken houd er dan rekening mee dat je een kwaliteitsborger moet inschakelen. Kwaliteitsborgers zijn onafhankelijke controleurs die controleren of een bouwwerk voldoet aan de wettelijke technische eisen. Dit doen ze zowel tijdens het ontwerp van het werk, als op de bouwplaats zelf. Wanneer er problemen worden geconstateerd kan de gemeente of de kwaliteitsborger de bouw stilleggen.',
    icon: LightningBoltIcon,
  },
];

const WkbPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Plan' }} />
    <BenefitHeroWKB
      heading={
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Wet Kwaliteitsborging</span>{' '}
          <span className="block text-yellow-400 xl:inline">in de praktijk</span>
        </h1>
      }
      subheading="Ieder bedrijf krijgt ermee te maken maar hoe pas je het toe?"
    />

    <BenefitFeaturesWkb
      heading="Wat verandert er?"
      subheading="Op 1 juli 2023 treedt de nieuwe Wet Kwaliteitsborging (Wkb) in werking. De belangrijkste zaken die voor jou als kleine aannemer veranderen hebben we hieronder uitgelegd. "
      features={FeaturesList}
    />

    <BenefitFeaturesImageWKB
      heading="Hoe pas je het toe in de praktijk?"
      subheading="Met de Homigo app voldoe je in drie simpele stappen aan de Wet!"
    />

    <BenefitCtaWKB
      heading={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="">Zien hoe Homigo jou kan helpen met de </span>
          <span className="text-primary"> WKB</span>
          <span className="">?</span>
        </h2>
      }
      heading2={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl pt-8">
          <span className="">Download</span>
          <span className="text-primary"> Homigo </span>
          <span className="">vandaag nog.</span>
        </h2>
      }
      subheading="Homigo is verkrijgbaar voor zowel IOS als Android en is ook te gebruiken als webversie op je computer."
    />
  </Layout>
);

export default WkbPage;
